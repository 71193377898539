var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-container",
        [
          _c("CommonTree", {
            attrs: {
              treeData: _vm.treeData,
              defaultProps: _vm.defaultProps,
              searchTitle: "title",
              isShowdig: true,
              "popover-show": true,
              showCheckbox: false,
              treeTitle: _vm.$t(`cip.plat.xxljob.task.title.actator`),
            },
            on: {
              getNodeClick: _vm.treeNodeClick,
              "include-down": _vm.includeDown,
            },
          }),
          _c(
            "el-main",
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    [
                      _c("head-layout", {
                        attrs: {
                          "head-title": _vm.$t(
                            `cip.plat.xxljob.dispatch.title.indexHeadTitle`
                          ),
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("grid-head-layout", {
                ref: "gridHeadLayout",
                attrs: {
                  "grid-head-btn": _vm.gridHeadBtn,
                  "search-columns": _vm.searchColumns,
                },
                on: {
                  "grid-head-search": _vm.gridHeadSearch,
                  "grid-head-empty": _vm.gridHeadEmpty,
                },
              }),
              _c("grid-layout", {
                ref: "gridLayOut",
                attrs: {
                  "grid-row-btn": _vm.gridRowBtn,
                  "table-options": _vm.tableOptions,
                  "table-data": _vm.tableData,
                  "table-loading": _vm.tableLoading,
                  "data-total": _vm.page.total,
                  page: _vm.page,
                },
                on: {
                  "page-current-change": _vm.onLoad,
                  "page-size-change": _vm.onLoad,
                  "page-refresh-change": _vm.onLoad,
                  "grid-row-detail-click": _vm.rowDetail,
                  "row-log": _vm.rowLog,
                  "row-handle": _vm.rowHandleLog,
                },
              }),
            ],
            1
          ),
          _vm.dialogFormVisible
            ? _c(
                "CommonDialog",
                {
                  attrs: {
                    width: "30%",
                    showConfirmBtn: false,
                    dialogTitle: _vm.$t(
                      `cip.plat.xxljob.dispatch.title.dispatchRemarkHeadTitle`
                    ),
                  },
                  on: {
                    cancel: function ($event) {
                      _vm.dialogFormVisible = false
                    },
                  },
                },
                [_c("p", { domProps: { innerHTML: _vm._s(_vm.triggerMsg) } })]
              )
            : _vm._e(),
          _vm.startLogVisible
            ? _c(
                "CommonDialog",
                {
                  attrs: {
                    width: "30%",
                    dialogTitle: _vm.$t(
                      `cip.plat.xxljob.dispatch.title.executionLogHeadTitle`
                    ),
                  },
                  on: {
                    cancel: function ($event) {
                      _vm.startLogVisible = false
                    },
                  },
                },
                [_c("p", { domProps: { innerHTML: _vm._s(_vm.handleMsg) } })]
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }