<template>
  <div>
    <el-container>
      <CommonTree
        :treeData="treeData"
        :defaultProps="defaultProps"
        searchTitle="title"
        :isShowdig="true"
        :popover-show="true"
        :showCheckbox="false"
        :treeTitle="$t(`cip.plat.xxljob.task.title.actator`)"
        @getNodeClick="treeNodeClick"
        @include-down="includeDown"
      />
      <el-main>
        <el-row>
          <el-col>
            <head-layout
              :head-title="$t(`cip.plat.xxljob.dispatch.title.indexHeadTitle`)"
            ></head-layout>
          </el-col>
        </el-row>
        <grid-head-layout
          ref="gridHeadLayout"
          :grid-head-btn="gridHeadBtn"
          :search-columns="searchColumns"
          @grid-head-search="gridHeadSearch"
          @grid-head-empty="gridHeadEmpty"
        ></grid-head-layout>
        <grid-layout
          ref="gridLayOut"
          :grid-row-btn="gridRowBtn"
          :table-options="tableOptions"
          :table-data="tableData"
          :table-loading="tableLoading"
          :data-total="page.total"
          :page="page"
          @page-current-change="onLoad"
          @page-size-change="onLoad"
          @page-refresh-change="onLoad"
          @grid-row-detail-click="rowDetail"

          @row-log="rowLog"
          @row-handle="rowHandleLog"
        >
          <!--      @row-remove="rowRemove"
                @row-edit="rowEdit"-->
        </grid-layout>
      </el-main>
      <CommonDialog v-if="dialogFormVisible" width="30%" :showConfirmBtn="false" :dialogTitle="$t(`cip.plat.xxljob.dispatch.title.dispatchRemarkHeadTitle`)" @cancel="dialogFormVisible = false">
        <p v-html="triggerMsg"></p>
      </CommonDialog>
      <CommonDialog v-if="startLogVisible" width="30%" :dialogTitle="$t(`cip.plat.xxljob.dispatch.title.executionLogHeadTitle`)" @cancel="startLogVisible = false">
      <p v-html="handleMsg"></p>
      </CommonDialog>
    </el-container>
  </div>
</template>

<script>

import {jobInfoTree} from "@/api/xxlJob/jobTaskList";
let baseUrl = '/api/sinoma-system/dict-biz/dictionary?code='
import {PAGE_CONSTANT} from "@/util/pageConstantEnum";
import {mapGetters} from "vuex";
import GridLayout from "@/views/components/layout/grid-layout";
import HeadLayout from "@/views/components/layout/head-layout";
import {checkLogin} from '@/util/xxljob';
import { getDictionary } from "@/api/system/dictbiz";

import {
  logDetail,
  pageList as getList
} from '@/api/xxlJob/jobDispatchList'
import CommonTree from "@/views/components/com_tree";
import CommonDialog from "@/components/CommonDialog";
export default {
  name: "dispatch_index",
  components: {
    GridLayout,
    HeadLayout,
    CommonTree,
    CommonDialog
  },
  data() {
    return {
      defaultProps: {
        label: "name",
        value: "id",
        key: "id"
      },
      taskId: '',
      node: {},
      treeData: [],
      triggerMsg: '',
      handleMsg: '',
      dialogFormVisible: false,
      startLogVisible: false,
      tableLoading: true,
      tableData: [],
      dataTotal: null,
      page: {
        pageSize: PAGE_CONSTANT.DEFAULT_PAGE_SIZE,
        currentPage: 1,
        total: 0
      },
      routeStrategy: "",
      RouteStrategy: "",
      blockStrategy: "",
      BlockStrategy: "",
    };
  },
  computed: {
    ...mapGetters(["permission",'language']),
    searchColumns() {
      return [
        {
          prop: "jobId",
          span: 4,
          placeholder: this.$t("cip.plat.xxljob.dispatch.field.jobId"),
        },
      ]
    },
    tableOptions() {
      return {
        menuWidth: 200,
        height: 0,
        column: [
          {
            label: this.$t('cip.plat.xxljob.dispatch.field.jobId'),
            prop: 'jobId',
            overHidden: true,
          },
          {
            label: this.$t('cip.plat.xxljob.task.field.jobDesc'),
            prop: 'jobDesc',
            minWidth: 140,
            overHidden: true,
          },
          {
            label: this.$t('cip.plat.xxljob.dispatch.field.triggerTime'),
            prop: 'triggerTime',
            align: "center",
            overHidden: true,
          },
          {
            label: this.$t('cip.plat.xxljob.dispatch.field.triggerCode'),
            prop: 'triggerCode',
            align: "center",
            type: 'select',
            dicData: [
              {
                label: ' ',
                value: 0
              },
              {
                label: this.$t('cip.plat.xxljob.dispatch.field.fail'),
                value: 500
              }, {
                label: this.$t('cip.plat.xxljob.dispatch.field.success'),
                value: 200
              }
            ]
          },
          {
            label: this.$t('cip.plat.xxljob.dispatch.field.handleTime'),
            prop: 'handleTime',
            align: "center",
            overHidden: true,
          },

          {
            label: this.$t('cip.plat.xxljob.dispatch.field.handleCode'),
            prop: 'handleCode',
            align: "center",
            type: 'select',
            dicData: [
              {
                label: ' ',
                value: 0
              },
              {
                label: this.$t('cip.plat.xxljob.dispatch.field.fail'),
                value: 500
              }, {
                label: this.$t('cip.plat.xxljob.dispatch.field.success'),
                value: 200
              }
            ]
          },

        ],
      }
    },
    gridHeadBtn() {
      return []
    },
    gridRowBtn() {
      let result = [];
      //新增按钮
      if (this.permission.dispatch_triggerLog) {
        result.push({
          label: this.$t('cip.plat.xxljob.dispatch.btn.triggerLogBtn'),
          emit: "row-log",
          type: "button",
          icon: ""
        });
      }

      if (this.permission.dispatch_handleLog) {
        result.push({
          label: this.$t('cip.plat.xxljob.dispatch.btn.handleLogBtn'),
          emit: "row-handle",
          type: "button",
          icon: ""
        });
      }
      return result;
    },
  },
  mounted() {
    this.initTree()
    this.taskId = this.$route.query.taskId
    if (this.taskId) {
      this.$refs.gridHeadLayout.searchForm.jobId = this.$route.query.taskId
    }
    this.onLoad(this.page, {});
  },
  methods: {
    // 点击左侧树节点
    treeNodeClick(node) {
      this.node = node;
      const {id} = node;
      this.cateId = id;
      this.page.total = 0;
      this.onLoad(this.page);
    },
    includeDown(e) {
      const params = {};
      this.page.currentPage = 1;
      params.isInclude = e;
      this.onLoad(this.page,params);
    },
    headRemove() {

    },
    initTree() {
      jobInfoTree().then(res => {
        let obj = {name: '全部分类', id: ''}
        this.treeData = res.data;
        if (this.treeData.length > 0) this.treeData.unshift(obj)
      });
    },
    headAddTabs() {
      this.$router.push({
        path: `/xxlJob/dispatch/edit`,
        query: {
          type: 'add',
          pageType: 'add',
        }
      })
    },
    // 无法处理后端国际化，只能在前端做拆解，期待大哥做一个好方法
    rowLog(row) {
      this.dialogFormVisible = true
      this.triggerMsg = row.triggerMsg
      let language = this.language
      const msg = row.triggerMsg.split("<br>")
      const arr = msg.slice(0,8)
      // 任务触发类型
      let type = arr[0].split("：")[1]
      if(language == "en" && type == "Cron触发"){
        type = "Cron trigger"
      }
      if(language == "en" && type == "手动触发"){
        type = "Manual trigger"
      }
      if(language == "en" && type == "父任务触发"){
        type = "Parent job trigger"
      }
      if(language == "en" && type == "API触发"){
        type = "Api trigger"
      }
      if(language == "en" && type == "失败重试触发"){
        type = "Fail retry trigger"
      }
      // 调度机器
      let adminAdress = arr[1].split("：")[1]
      // 执行器-注册方式
      let exeRegtype = arr[2].split("：")[1]
      if(language == "en" && exeRegtype == "手动录入"){
        exeRegtype = "Manual entry"
      }
      if(language == "en" && exeRegtype == "自动注册"){
        exeRegtype = "automatic logon"
      }
      // 执行器-地址列表
      let exeRegaddress = arr[3].split("：")[1]
      // 路由策略
      this.routeStrategy = arr[4].split("：")[1]
      getDictionary({code: 'EXECUTOR_ROUTE_STRATEGY'}).then(res=>{
        const {data} = res.data
        if(this.routeStrategy == '第一个'){
          this.routeStrategy = data.filter(e => e.dictKey == 'FIRST')[0].dictValue
        } else if(this.routeStrategy == '最后一个'){
          this.routeStrategy = data.filter(e => e.dictKey == 'LAST')[0].dictValue
        } else if(this.routeStrategy == '轮询'){
          this.routeStrategy = data.filter(e => e.dictKey == 'ROUND')[0].dictValue
        } else if(this.routeStrategy == '随机'){
          this.routeStrategy = data.filter(e => e.dictKey == 'RANDOM')[0].dictValue
        } else if(this.routeStrategy == '一致性HASH'){
          this.routeStrategy = data.filter(e => e.dictKey == 'CONSISTENT_HASH')[0].dictValue
        } else if(this.routeStrategy == '最不经常使用'){
          this.routeStrategy = data.filter(e => e.dictKey == 'LEAST_FREQUENTLY_USED')[0].dictValue
        } else if(this.routeStrategy == '最近最久未使用'){
          this.routeStrategy = data.filter(e => e.dictKey == 'LEAST_RECENTLY_USED')[0].dictValue
        } else if(this.routeStrategy == '故障转移'){
          this.routeStrategy = data.filter(e => e.dictKey == 'FAILOVER')[0].dictValue
        } else if(this.routeStrategy == '忙碌转移'){
          this.routeStrategy = data.filter(e => e.dictKey == 'BUSYOVER')[0].dictValue
        } else if(this.routeStrategy == '分片广播'){
          this.routeStrategy = data.filter(e => e.dictKey == 'SHARDING_BROADCAST')[0].dictValue
        } else{
          this.routeStrategy = this.$t('cip.plat.xxljob.dispatch.msg.error')
        }
      })
      // 阻塞处理策略
      this.blockStrategy = arr[5].split("：")[1]
      getDictionary({code: 'EXECUTOR_BLOCK_STRATEGY'}).then(res=>{
        const {data} = res.data
        if(this.blockStrategy == '单机串行'){
          this.blockStrategy = data.filter(e => e.dictKey == 'SERIAL_EXECUTION')[0].dictValue
        } else if(this.blockStrategy == '丢弃后续调度'){
          this.blockStrategy = data.filter(e => e.dictKey == 'DISCARD_LATER')[0].dictValue
        } else if(this.blockStrategy == '覆盖之前调度'){
          this.blockStrategy = data.filter(e => e.dictKey == 'COVER_EARLY')[0].dictValue
        } else{
          this.blockStrategy = this.$t('cip.plat.xxljob.dispatch.msg.error')
        }
        console.log(this.blockStrategy);
      })
      // 任务超时时间
      let timeout = arr[6].split("：")[1]
      // 失败重试次数
      let failRetryCount = arr[7].split("：")[1]
      let a = this.$t('cip.plat.xxljob.dispatch.field.type') + "：" + type + "<br>"
      let b = this.$t('cip.plat.xxljob.dispatch.field.adminAdress') + "：" + adminAdress + "<br>"
      let c = this.$t('cip.plat.xxljob.dispatch.field.exeRegtype') + "：" + exeRegtype + "<br>"
      let d = this.$t('cip.plat.xxljob.dispatch.field.exeRegaddress') + "：" + exeRegaddress + "<br>"
      let g = this.$t('cip.plat.xxljob.dispatch.field.timeout') + "：" + timeout + "<br>"
      let h = this.$t('cip.plat.xxljob.dispatch.field.failRetryCount') + "：" + failRetryCount + "<br>" + "<br>"
      let i = this.$t('cip.plat.xxljob.dispatch.field.divider') + "<br>"
      let j = this.$t('cip.plat.xxljob.dispatch.field.triggerScheduling') + "：" + "<br>"
      let k = msg[11] + "<br>"
      let l = msg[12] + "<br>"
      let m = msg[13]
      this.$nextTick(() => {
        setTimeout(() => {
          this.RouteStrategy = this.$t('cip.plat.xxljob.dispatch.field.routeStrategy') + "：" + this.routeStrategy + "<br>"
          this.blockStrategy = this.$t('cip.plat.xxljob.dispatch.field.blockStrategy') + "：" + this.blockStrategy + "<br>"
          this.triggerMsg = a + b + c + d + this.RouteStrategy + this.blockStrategy + g + h + i + j + k + l + m
        }, 1000)
      })
    },
    rowHandleLog(row) {
      this.startLogVisible = true
      this.handleMsg = row.handleMsg
    },
    rowRemove(row) {
      this.$router.push({
        path: `/xxlJob/dispatch/edit`,
        query: {
          id: row.id,
          type: 'edit',
          pageType: 'edit',
        }
      })
    },
    rowDetail(row) {
      this.$router.push({
        path: `/xxlJob/dispatch/edit`,
        query: {
          id: row.id,
          type: 'view',
          pageType: 'detail',
        }
      })
    },
    rowEdit(row) {
      logDetail(row.id).then(res => {
        console.log("res", res.data.content)
      });
    },
    gridHeadSearch(query) {
      this.onLoad(this.page, query)
    },
    gridHeadEmpty() {
      this.onLoad(this.page)
    },
    onLoad(page, params = {}) {
      let that = this
      checkLogin(function () {
        that.page = page;
        that.tableLoading = true;
        // JSON.stringify(that.$refs.gridHeadLayout.searchForm)
        let searchForm = {}
        searchForm.jobGroup = that.$refs.gridHeadLayout.searchForm.jobGroup?that.$refs.gridHeadLayout.searchForm.jobGroup:-1
        searchForm.jobId = that.$refs.gridHeadLayout.searchForm.jobId?that.$refs.gridHeadLayout.searchForm.jobId:-1
        searchForm.logStatus = that.$refs.gridHeadLayout.searchForm.logStatus?that.$refs.gridHeadLayout.searchForm.logStatus:-1
        searchForm.filterTime = that.$refs.gridHeadLayout.searchForm.filterTime?that.$refs.gridHeadLayout.searchForm.filterTime:""
        searchForm.id = that.node.id
        searchForm.isJob = that.node.job

        getList(page.currentPage, page.pageSize, Object.assign(searchForm, params)).then(res => {
          const data = res.data;
          that.$refs.gridLayOut.page.total = data.recordsTotal;
          that.tableData = data.data;
          that.tableLoading = false;
        });
      })
    }
  }
};
</script>
<style>
</style>
